<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader class="bg-secondary h3"><i class="ri-printer-line ri-lg"></i> ผลงาน/รางวัล
          ของโรงเรียน</CCardHeader>
        <CCardBody>
          <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" :search-options="{ enabled: true }"
            :sort-options="{
                enabled: true,
                initialSortBy: { field: 'reward_id', type: 'desc' },
              }" :pagination-options="{ enabled: true, mode: 'page', perPage: 30 }">
            >
            <div slot="table-actions">
              <button class="btn btn-primary" style="margin-right: 10px" @click="modalCreate = true">
                +add
              </button>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'tools'">
                <CDropdown>
                  <template #toggler>
                    <CHeaderNavLink>
                      <button class="btn btn-secondary btn-block">Tools</button>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem>
                    <a class="text-center" style="width: 100%" @click="$router.push({
                        path: `/rewardView/${props.row.reward_id}`,
                      })
                      ">
                      view
                    </a>
                  </CDropdownItem>

                  <CDropdownItem v-if="props.row.reward_who_create == parseInt(userData.user_id)
                    ">
                    <div class="text-center" style="width: 100%" @click="openModalEdit(props.row.reward_id)">
                      edit
                    </div>
                  </CDropdownItem>
                </CDropdown>
              </span>
            </template>
          </vue-good-table>
          <CRow style="margin-top: 20px">
            <CCol>
              <div style="text-align: right">
                <button @click="onExport" class="btn btn-secondary">Export</button>
                <!-- เพิ่มปุ่ม Export -->
              </div>
            </CCol>
          </CRow>
        </CCardBody>

      </CCard>
    </CCol>
    <!-------------------MODAL CREATE--------------------------------------------------------------------------------------->
    <form id="formCreateLog" v-on:submit.prevent="createReward" method="POST">
      <CModal :show.sync="modalCreate" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow>
          <!--body-->

          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสผู้รายงาน:
              </label>
              <input type="text" name="userId" class="form-control" readonly :value="userData.user_id" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน:
              </label>
              <input type="text" name="schoolCode" class="form-control" readonly :value="userData.user_address" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <SltRewardType />
          </CCol>
          <CCol lg="6" v-if="userData">
            <SltRewardLevel />
          </CCol>
          <CCol lg="12" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ชื่อผลงาน/ รางวัล:
              </label>
              <input type="text" name="rewardName" class="form-control" required />
            </div>
          </CCol>
          <CCol lg="12" v-if="userData">
            <ChkRewardStandard />
          </CCol>
          <CCol lg="12" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รายละเอียดเพิ่มเติม เช่น รายชื่อนักเรียน รายชื่อครู:
              </label>
              <textarea class="form-control" id="exampleFormControlText1" name="rewardDescription" rows="3"
                required></textarea>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                วันที่ได้รับ:
              </label>
              <datepicker name="rewardDate" input-class="form-control bg-white" :required="true" format="yyyy-MM-dd"
                placeholder="คลิ๊ก" v-model="startDate"></datepicker>
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ภาพประกอบรายงาน:
              </label>

              <UploadImages @changed="handleImages" :max="1" maxError="อัพโหลดได้เพียง 1 ภาพ เท่านั้น"
                uploadMsg="คลิ๊กเพื่อเลือกภาพ" />
            </div>
          </CCol>
          <CCol lg="12" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                จากหน่วยงาน:
              </label>
              <input type="text" name="rewardOwner" class="form-control" required />
            </div>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">บันทึกผลงานของโรงเรียน</h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="danger">Discard</CButton>

          <button type="submit" class="btn btn-info" :disabled="btnCreate.disable">
            {{ btnCreate.txt }}
          </button>
          <div v-if="btnCreate.disable">
            <div class="spinner-border text-info" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </template>
      </CModal>
    </form>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SltRewardType from "../components/SltRewardType.vue";
import SltRewardLevel from "../components/SltRewardLevel.vue";
import ChkRewardStandard from "../components/ChkRewardStandard.vue";
import Datepicker from "vuejs-datepicker";
import UploadImages from "vue-upload-drop-images";
import XLSX from "xlsx"; // import xlsx;

export default {
  name: "Colors",
  components: {
    VueGoodTable,
    SltRewardType,
    SltRewardLevel,
    ChkRewardStandard,
    Datepicker,
    UploadImages,
    XLSX,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      rows: null,
      rowsExcel: null,
      columns: [
        {
          label: "#",
          field: "reward_id",
          type: "number",
        },
        {
          label: "ประเภท",
          field: "reward_typ_name",
        },

        {
          label: "ระดับ",
          field: "reward_level_name",
          type: "text",
        },

        {
          label: "ชื่อรางวัล",
          field: "reward_name",
          type: "text",
        },
        {
          label: "มาตรฐาน/ตัวชี้วัด",
          field: "reward_standard",
        },

        {
          label: "Tools",
          field: "tools",
        },
      ],
      modalCreate: false,
      btnCreate: { txt: "save", disable: false },
      startDate: null,
      selectedFiles: null,
    };
  },
  mounted() {
    //FETCH ALL SCHOOL REWARDS
    let formData = new FormData();
    formData.append("txtAction", "getAllReward");
    formData.append("schoolCode", this.userData.user_address);
    this.axios
      .post(this.$hostUrl + "php_action/rewardAPI.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log(response.data);
        this.rows = response.data.mainData;
        this.rowsExcel = response.data.excelData;
      });
  },
  methods: {
    createReward(event) {
      //console.log(this.startDate);
      //console.log(this.$store.state.selectedRewardType);
      //console.log(this.$store.state.selectedRewardLevel);
      if (!this.$store.state.selectedRewardType) {
        alert("กรุณาเลือกประเภทของรางวัล");
        return false;
      }
      if (!this.$store.state.selectedRewardLevel) {
        alert("กรุณาเลือกระดับของรางวัล");
        return false;
      }
      if (this.$store.state.checkedSubStandard.length == 0) {
        alert("กรุณาอย่างน้อย 1 มาตรฐานที่เกี่ยวกับรางวัล");
        return false;
      }
      if (!this.startDate) {
        alert("กรุณาวันที่ได้รับรางวัล");
        return false;
      }
      this.btnCreate = { txt: "sending..", disable: true };
      const form = document.getElementById(event.target.id);
      let formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("rewardType", this.$store.state.selectedRewardType.value);
      formData.append(
        "rewardLevel",
        this.$store.state.selectedRewardLevel.value
      );
      formData.append(
        "rewardStandard",
        JSON.stringify(this.$store.state.checkedSubStandard)
      );
      if (this.selectedFiles) {
        formData.append("file", this.selectedFiles[0]);
      }

      this.axios
        .post(this.$hostUrl + "php_action/rewardAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกรางวัลสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
            this.btnCreate = { txt: "save", disable: false };
          }
        });
    },
    handleImages(files) {
      this.selectedFiles = files;
      console.log(this.selectedFiles[0]);
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
