<template>
  <div class="relative w-full mb-3">
    <div style="margin-top:0px" class="text-primary"><strong>การสนองมาตรฐานการประเมินภายใน:</strong> </div>
    <div v-for="(standard, n) in $store.state.standardChk" :key="standard.id">
      <strong>{{ standard.standardName }}:</strong>
      <div
        class="form-check"
        v-for="subStandard in $store.state.standardChk[n].subStandard"
        :key="subStandard.id"
      >
        <input
          class="form-check-input"
          type="checkbox"
          name="rewardStandard[]"
          :value="subStandard.subStandardId"
          :id="modalType + 'flexCheck' + subStandard.subStandardId"
          v-model="$store.state.checkedSubStandard"
        />
        <label
          class="form-check-label"
          :for="'flexCheckDefault' + subStandard.subStandardId"
        >
          {{ subStandard.subStandardName }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  props: {
    modalType: String,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      darkModal: false,
    };
  },
  mounted() {
    //SET USER DATA
    this.userData = this.$cookies.get("user");

    //FETCH PERIOD DATA
    let formData = new FormData();
    formData.append("txtAction", "getAllPeriod");
    this.axios
      .post(this.$hostUrl + "php_action/periodAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.$store.commit("setPeriodChk", response.data.mainData);
      });
  },
  methods: {
    sendData() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
  width: 0px;
}
</style>
