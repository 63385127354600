<template>
  <div>
    <TblSchoolReward/>
  </div>
</template>

<script>
import TblSchoolReward from "./components/TblSchoolReward";


export default {
  name: "Dashboard",
  components: {
    TblSchoolReward,

  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {},
};
</script>
